import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Lunyby 404: You can't always get what you want" />
    <h1>You can't always get what you want.</h1>
    <p>This is a perfect example. What you are looking for does not exist. But the sadness is only temporary. <a href="/">Here</a></p>
  </Layout>
)

export default NotFoundPage
